var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.dialog
        ? _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "title" }, [_vm._v("New User")])
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "pa-0": "", "grid-list-lg": "" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.rules.required],
                              height: 30,
                              required: "",
                              clearable: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [_c("RequiredDot", [_vm._v("Name")])]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3638332957
                            ),
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name"
                            }
                          }),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _vm.productName !== "redis"
                                ? [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            "hide-details":
                                              _vm.formData.permission !==
                                              "Super",
                                            rules: [
                                              _vm.rules.required,
                                              _vm.rules.noNumOnly
                                            ],
                                            items: [
                                              "Super",
                                              "Read-Write",
                                              "Read-Only"
                                            ]
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c("RequiredDot", [
                                                      _vm._v("Permission")
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            2416406289
                                          ),
                                          model: {
                                            value: _vm.formData.permission,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "permission",
                                                $$v
                                              )
                                            },
                                            expression: "formData.permission"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formData.permission ===
                                              "Super",
                                            expression:
                                              "formData.permission==='Super'"
                                          }
                                        ],
                                        staticClass:
                                          "orange--text text--darken-3",
                                        staticStyle: {
                                          "font-size": "14px",
                                          height: "77px"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "orange darken-3",
                                              small: ""
                                            }
                                          },
                                          [_vm._v("warning")]
                                        ),
                                        _vm._v(
                                          " Please do not change the default administrator password using super users, otherwise some application management features will be limited.\n                            "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.formData.permission !== "Super"
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                dense: "",
                                                "deletable-chips": "",
                                                "return-object": "",
                                                "item-text": "name",
                                                rules: [
                                                  _vm.rules.arrayRequired
                                                ],
                                                items: _vm.existDBs,
                                                chips: "",
                                                multiple: "",
                                                "persistent-hint": "",
                                                hint:
                                                  "Database the user will have permission to access"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function() {
                                                      return [
                                                        _c("RequiredDot", [
                                                          _vm._v("Database")
                                                        ])
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                2373692895
                                              ),
                                              model: {
                                                value: _vm.formData.database,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "database",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.database"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              _vm.showPassword
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.pwdRules,
                                          type: "password",
                                          "persistent-hint": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c("RequiredDot", [
                                                    _vm._v("Password")
                                                  ])
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1597556645
                                        ),
                                        model: {
                                          value: _vm.formData.password,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "formData.password"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showPassword
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.same
                                          ],
                                          type: "password"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c("RequiredDot", [
                                                    _vm._v("Confirm Password")
                                                  ])
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1189809399
                                        ),
                                        model: {
                                          value: _vm.formData.passwordConfirm,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "passwordConfirm",
                                              $$v
                                            )
                                          },
                                          expression: "formData.passwordConfirm"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "blue darken-1", flat: "" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "primary", loading: _vm.loading },
                          on: { click: _vm.ok }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }