<template>
    <v-container class="panel-wrapper panel-metrics pa-0" grid-list-lg fluid>
        <v-layout column>
            <template v-if="productName ==='oracle'">
                <v-flex>
                    <OracleDatabases></OracleDatabases>
                </v-flex>
                <v-flex>
                    <OracleUsers></OracleUsers>
                </v-flex>
            </template>
            <template v-else-if="productName ==='kafka'">
                <v-flex>
                    <KafkaDatabases></KafkaDatabases>
                </v-flex>
                <v-flex>
                    <KafkaUsers></KafkaUsers>
                </v-flex>
            </template>
            <template v-else>
                <v-flex>
                    <Databases ></Databases>
                </v-flex>
                <v-flex>
                    <Users></Users>
                </v-flex>
            </template>
            <v-flex v-if="['sql server','mysql'].indexOf(productName) !== -1">
                <DataSync></DataSync>
            </v-flex>
            <v-flex>
                <v-card elevation="0">
                    <v-card-text>
                        <Help :mod="['menu_tab_db_user']" :product="appInfo.product_name"></Help>
                    </v-card-text>
                </v-card>
            </v-flex>
<!--            <v-flex>-->
<!--                <template  v-if="productName==='mysql'">-->
<!--                    <MysqlConnectionInfo></MysqlConnectionInfo>-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                    <ConnectionInfo></ConnectionInfo>-->
<!--                </template>-->
<!--            </v-flex>-->
<!--            <v-flex v-if="deploymentInfo.import_export_info && deploymentInfo.import_export_info.length">-->
<!--                <TabCard title="Data Import and Export">-->
<!--                    <v-layout column>-->
<!--                        <template v-for="(group, gid) in deploymentInfo.import_export_info">-->
<!--                            <v-flex :key="gid">-->
<!--                                <p style="font-size: 14px;color: #666;margin: 0;" v-html="group.description"></p>-->
<!--                            </v-flex>-->
<!--                            <v-flex :key="gid">-->
<!--                                <v-layout wrap>-->
<!--                                    <v-flex xs12 v-for="(item, idx ) in group.cmds" :key="idx">-->
<!--                                        <template v-if="item.type==='shell'">-->
<!--                                            <SmallBlock :title="item.name">-->
<!--                                                <kbd v-if="item.value" style="min-width: 100%;word-break: break-all;">{{item.value}}</kbd>-->
<!--                                            </SmallBlock>-->
<!--                                        </template>-->
<!--                                        <template v-else>-->
<!--                                            <SmallBlock :title="`${item.name} : ${item.value}`"></SmallBlock>-->
<!--                                        </template>-->
<!--                                    </v-flex>-->
<!--                                </v-layout>-->
<!--                            </v-flex>-->
<!--                        </template>-->
<!--                    </v-layout>-->
<!--                </TabCard>-->
<!--            </v-flex>-->
        </v-layout>
    </v-container>
</template>

<script>
    import Users from "./users/DBUsers";
    import Databases from "./databases/Databases";
    import OracleDatabases from "./oracle/databases/Databases";
    import OracleUsers from "./oracle/users/DBUsers";
    import KafkaDatabases from "./kafka/databases/Databases";
    import KafkaUsers from "./kafka/users/DBUsers";
    import DataSync from "./sync/DataSync";
    import {mapActions, mapState} from 'vuex' //  mapGetters
    // import TabCard from '@/components/TabCard'
    // import ConnectionInfo  from '@/views/applications/overview/_source/connectionInfo/index'
    // import MysqlConnectionInfo from '@/views/applications/overview/_source/mysqlConnectionInfo/index'

    // import SmallBlock from '@/components/SmallBlock'
    import Help from '@/components/Help'

    export default {
        name: "DbUser",
        components: { Users,Databases,OracleDatabases,OracleUsers ,KafkaDatabases,KafkaUsers,Help,DataSync},
        data() {
            return {
                showDbDetail:false,
                tabs: [
                    {icon: '', text: 'Databases', path: `/applications/${this.$route.params.appId}/dbuser/databases`},
                    {icon: '', text: 'Users', path: `/applications/${this.$route.params.appId}/dbuser/users`},
                ],
                headers: [
                    {text: 'Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Size', value: 'size', sortable: false, align: 'right',},
                    {text: 'Created on', value: 'created', sortable: false, align: 'right',},
                    {text: '', value: 'opt', sortable: false, align: 'right',},
                ],
                desserts: (function () {
                    let arr = [];
                    for (let i = 0; i < 4; i++) {
                        arr.push({
                            name: 'Frozen Yogurt',
                            size: 159,
                            created: 6.0,
                            opt: ''
                        })
                    }
                    return arr

                })(),
                connections: ['Import', 'Export']
            }
        },
        computed: {
            productName() {
                return this.appInfo.product_name.toLowerCase()
            },
            ...mapState('application', ['deploymentInfo',"appInfo"]),
        },
        methods: {
            ...mapActions('application', ['getDataBaseList', 'getDataBaseUserList', 'getConnectionInfo']),
        },
        created() {
            this.getConnectionInfo()
            Promise.all([this.getDataBaseList(),this.getDataBaseUserList()])
            this.$SDK.track({pageName:'DB & User',productName:this.appInfo.product_name})
        },
        mounted() {

        },
    }
</script>

<style lang="scss">

</style>
