var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer" }, [
    _c("div", { class: _vm.maskClass, on: { click: _vm.closeByMask } }),
    _c(
      "div",
      { staticClass: "main", class: _vm.mainClass, style: _vm.mainStyle },
      [
        _c(
          "v-card",
          { staticClass: "drawer-main-card", class: _vm.cardClass },
          [
            _vm.$slots.title
              ? _c("v-card-title", [_vm._t("title")], 2)
              : _vm._e(),
            _c("div", { staticClass: "cnt " }, [_vm._t("default")], 2),
            _vm.$slots.actions
              ? _c("v-card-actions", [_vm._t("actions")], 2)
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }