var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      staticClass: "detail-drawer",
      attrs: { display: _vm.openDetail, width: "1000" },
      on: {
        "update:display": function($event) {
          _vm.openDetail = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "title-wrapper pl-20",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("Synchromize Application Data")
          ]),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "ma-0", attrs: { column: "" } },
        [
          !_vm.forbidden
            ? _c("v-flex", { staticClass: " pl-20" }, [
                _c("p", { staticClass: "tips" }, [
                  _vm._v(
                    "Are you sure you want to synchronize the following data to the Control Panel?"
                  )
                ])
              ])
            : _c("v-flex", [
                _c("p", [_vm._v("No data is available for synchronization.")])
              ]),
          _c(
            "v-flex",
            [
              _c(
                "v-data-table",
                {
                  staticClass: "elevation-1",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.items,
                    loading: _vm.loading,
                    "hide-actions": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("td", { staticClass: "text-xs-left" }, [
                            _vm._v(_vm._s(item.database))
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _vm._l(item.users, function(it, index) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      key: index,
                                      attrs: {
                                        small: "",
                                        outline: "",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(_vm._s(it))]
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("v-progress-linear", {
                    attrs: { color: "blue", indeterminate: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "progress",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "actions-wrapper",
          attrs: { slot: "actions" },
          slot: "actions"
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-none",
              attrs: {
                color: "blue darken-1",
                flat: "",
                disabled: _vm.loading
              },
              on: {
                click: function($event) {
                  _vm.openDetail = false
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                small: "",
                loading: _vm.loading,
                disabled: _vm.forbidden
              },
              on: { click: _vm.syncData }
            },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }