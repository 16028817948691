var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        {
          directives: [
            {
              name: "insert",
              rawName: "v-insert",
              value: {
                tagName: "input",
                attrs: { style: "display:none", type: "text" }
              },
              expression:
                "{tagName:'input',attrs:{style:'display:none',type:'text'}}"
            }
          ],
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "span",
                  { staticClass: "title" },
                  [_vm._t("default", [_vm._v("Create Tablespace")])],
                  2
                )
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "pa-0": "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          height: 30,
                          required: "",
                          clearable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [_c("RequiredDot", [_vm._v("Name")])]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.close }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "primary", loading: _vm.loading },
                      on: { click: _vm.ok }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }