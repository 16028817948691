var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tab-card",
    {
      attrs: {
        title:
          "Databases" + (_vm.productName === "cassandra" ? "(Keyspace)" : "")
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-data-table",
                {
                  staticClass: "elevation-1",
                  attrs: {
                    headers:
                      _vm.productName === "redis" ? _vm.RdHeaders : _vm.headers,
                    items: _vm.currentDataBases,
                    loading: _vm.loading,
                    "hide-actions": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.productName !== "redis"
                            ? [
                                _c(
                                  "td",
                                  [
                                    item.status !== "SqlRemove"
                                      ? _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              "text-decoration": "underline"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.DBdetail.open(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      : _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g({}, on),
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "The database has been destroyed."
                                              )
                                            ])
                                          ]
                                        )
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-xs-left" },
                                  [
                                    _vm._l(item.database_users, function(
                                      it,
                                      index
                                    ) {
                                      return [
                                        it.status !== "SqlRemove"
                                          ? _c(
                                              "v-chip",
                                              {
                                                key: index,
                                                class: {
                                                  "user-chip": !(
                                                    it.is_super || it.is_other
                                                  )
                                                },
                                                attrs: {
                                                  small: "",
                                                  color:
                                                    !(
                                                      it.is_super || it.is_other
                                                    ) && "primary",
                                                  "text-color":
                                                    !(
                                                      it.is_super || it.is_other
                                                    ) && "#ffffff"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toUserDetail(it)
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(it.name) +
                                                    " " +
                                                    _vm._s(
                                                      it.is_super
                                                        ? "(Super)"
                                                        : it.is_other
                                                        ? "(Others)"
                                                        : ""
                                                    ) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                ),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("formatDate")(item.created))
                                  )
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "44px"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            transition: "slide-x-transition"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { small: "" }
                                                          },
                                                          [_vm._v("more_vert")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-tile",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.delDB.open(
                                                        { item: item }
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-list-tile-title", [
                                                    _vm._v("Delete")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            : [
                                _c("td", { staticClass: "text-xs-left" }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c("td", { staticClass: "text-xs-center" }, [
                                  _vm._v(_vm._s("" + (item.keys || "--")))
                                ])
                              ]
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "min-height": "192px",
                                position: "relative"
                              }
                            },
                            [
                              _c("Spin", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ]
                              }),
                              _c(
                                "Empty",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading,
                                      expression: "!loading"
                                    }
                                  ]
                                },
                                [
                                  _vm.productName === "redis"
                                    ? _c("span", [
                                        _vm._v(
                                          "By default, Redis has 0-15 indexes for databases.\n                             "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "You don't need to create a database in Redis, and you can use the SELECT command to select the database you want to work on.\n                            "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-progress-linear", {
                    attrs: { color: "blue", indeterminate: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "progress",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.dataBases.length > 10
            ? _c(
                "v-flex",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("v-pagination", {
                    attrs: {
                      "total-visible": "5",
                      length: Math.ceil(_vm.dataBases.length / 10)
                    },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { staticStyle: { padding: "20px 0 0" } },
            [
              _vm.productName !== "redis"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: {
                        id: "create-db",
                        title:
                          "Create a database so you can start working on your database.",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.addDB.open()
                        }
                      }
                    },
                    [_vm._v(" Create Database\n            ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Confirm",
        {
          ref: "delDB",
          on: { confirm: _vm.deleteDatabase },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function(ref) {
                var options = ref.options
                return [
                  options.item && options.item.name
                    ? _c("span", [
                        _vm._v(
                          "This action is undoable. Are you sure you want to delete database "
                        ),
                        _c("b", [_vm._v(" " + _vm._s(options.item.name))]),
                        _vm._v("?")
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Delete Database")
          ])
        ]
      ),
      _c("AddDB", {
        ref: "addDB",
        attrs: { "character-set": _vm.needCharacterSet },
        on: { confirm: _vm.addDatabase }
      }),
      _c("DbDetail", { ref: "DBdetail" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }