<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation>
            <v-card>
                <v-card-title>
                    <span class="title" style="display: block;">Reset {{formData.item.name}}'s Password</span><br/>
                </v-card-title>
                 <slot name="subtitle" :options="formData"></slot>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid pa-0 grid-list-lg>
                        <v-text-field
                                v-model="formData.password"
                                type="password"
                                :rules="rules"
                                required
                                clearable>
                            <template v-slot:label>
                                <RequiredDot>New password</RequiredDot>
                            </template>
                        </v-text-field>
                        <v-text-field
                                type="password"
                                :rules="[value => !value||value===formData.password||'Passwords do not match.',...rules]"
                                clearable>
                            <template v-slot:label>
                                <RequiredDot>New Password Confirmation</RequiredDot>
                            </template>
                        </v-text-field>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import RequiredDot from '@/components/RequiredDot.vue'
    import rules from '@/module/rules/index.js'
    import {mapState} from 'vuex'

    export default {
        name: "UserManage",
        mixins: [loadingMixin],
        components: {RequiredDot},
        data() {
            return {
                formData: {
                    name: '',
                    password: '',
                    item:{}
                }
            }
        },
        computed: {
            rules() {
                let arr = [rules.required,rules.moreCounter]
                this.appInfo.product_name.toLowerCase()=== 'sql server' && arr.push(rules.n_c_C)
                return  arr
            },
            ...mapState('application', ['appInfo'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.formData =  {
                        name: '',
                        password: '',
                        item:{}
                    }
                }
            }
        },
        methods: {
            open(options){
                this.formData = Object.assign(this.formData,options);
                this.dialog = true
            },
            $validator(){
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">

</style>
