var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.dialog
        ? _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", {
                      staticClass: "title",
                      domProps: {
                        textContent: _vm._s(
                          _vm.formData.action === "update"
                            ? "Update Accessible Topic"
                            : "Add Accessible Topic"
                        )
                      }
                    })
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "pa-0": "", "grid-list-lg": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  disabled: "",
                                  "return-object": "",
                                  lable: "User",
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.formData.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "name", $$v)
                                  },
                                  expression: "formData.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.formData.action === "update",
                              dense: "",
                              chips: "",
                              multiple: "",
                              "deletable-chips": "",
                              "item-text": "name",
                              "item-value": "uuid",
                              rules: [_vm.rules.arrayRequired],
                              items:
                                _vm.formData.action === "update"
                                  ? _vm.dataBases
                                  : _vm.databasesList,
                              "persistent-hint": "",
                              hint:
                                "Please choose the topic that you would like the user to have access to."
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c("RequiredDot", [_vm._v("Topic")])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3737559579
                            ),
                            model: {
                              value: _vm.formData.databases,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "databases", $$v)
                              },
                              expression: "formData.databases"
                            }
                          }),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  rules: [_vm.rules.required],
                                  items: [
                                    "Produce",
                                    "Consume",
                                    "Produce and Consume"
                                  ]
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("RequiredDot", [
                                            _vm._v("Permission")
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2416406289
                                ),
                                model: {
                                  value: _vm.formData.permission,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "permission", $$v)
                                  },
                                  expression: "formData.permission"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "blue darken-1", flat: "" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "primary", loading: _vm.loading },
                          on: { click: _vm.ok }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }