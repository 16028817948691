<template>
    <tab-card title="Users">
        <v-layout column>
            <v-flex>
                <v-data-table
                        :headers="headers"
                        :items="users"
                        :loading="loading"
                        hide-actions
                        class="elevation-1">
                    <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                    <template v-slot:items="{item}">
                        <td>
                            <a style="text-decoration: underline" v-if="item.databases[0]!== 'all'&&item.status==='Active'&&productName!=='Redis'" @click="$refs.userDetail.open(item)">{{ item.name }}</a>
                            <v-tooltip top v-else :disabled="item.status!=='SqlRemove'">
                                <template v-slot:activator="{on}">
                                    <span v-on="on">{{item.name}}</span>
                                </template>
                                <span>The user has been deleted.</span>
                            </v-tooltip>
                        </td>
                        <td class="text-xs-left" v-if="productName!=='Redis'">
                            <template v-for="(it,index) in item.databases" >
                                <v-chip small  v-if="it.status!=='SqlRemove'" :color="it.name&&'primary'" :text-color="it.name&&'#ffffff'" @click="toDbDetail(it)" :key="index">{{it.name?it.name:it}}</v-chip>
                            </template>
                        </td>
                        <td class="text-xs-right">{{ item.created | formatDate }}</td>
                        <td class="text-xs-right">
                          <span style="display:inline-block;width: 44px;">
                            <v-menu transition="slide-x-transition">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon small v-on="on">
                                        <v-icon small>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-tile v-if="item.status!=='SqlRemove'" @click="$refs.userm.open({item:item})">
                                        <v-list-tile-title>Reset Password</v-list-tile-title>
                                    </v-list-tile>
                                    <v-list-tile @click="$refs.delUser.open({item:item})">
                                        <v-list-tile-title>Delete</v-list-tile-title>
                                    </v-list-tile>
                                </v-list>
                            </v-menu>
                        </span>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <div style="min-height: 192px;position: relative;" >
                            <Spin v-show="loading"></Spin>
                            <Empty v-show="!loading"></Empty>
                        </div>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex style="padding : 30px 0 0;">
                <v-btn id="create-user" :title="`Create a user so you can start working on your topic.`" class="text-none" color="primary" @click="$refs.addUser.open()"> Create User</v-btn>
            </v-flex>
        </v-layout>
        <AddDBUser  ref="addUser" show-password @confirm="addUser"></AddDBUser>
        <UserManage ref="userm" @confirm="updateUser"></UserManage>
        <UserDetail ref="userDetail"></UserDetail>
        <Confirm ref="delUser" @confirm="deleteUser">
            <span slot="title">Delete  User</span>
            <template v-slot:content="{options}">
                <span v-if="options.item&& options.item.name">Are you sure you want to delete user <b> {{options.item.name}}</b>?</span>
            </template>
        </Confirm>
    </tab-card>
</template>

<script>
    import AddDBUser from '../_source/AddDBUser.vue'
    import UserManage from '../_source/UserManage.vue'
    import UserDetail from  './UserDetail.vue'
    import Confirm from '@/components/Confirm.vue'
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import {formatDate} from '@/module/utils/date'
    import {findComponentDownward} from '@/module/utils/assist.js'
    import {mapState, mapGetters ,mapActions} from 'vuex'
    import TabCard from "@/components/TabCard"; //mapState  mapGetters mapActions

    export default {
        name: "KDBUsers",
        components: {TabCard, AddDBUser, UserManage,UserDetail, Confirm ,Empty, Spin},
        data() {
            return {
                loading : false,
                headers: [
                    {text: 'Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Accessible Topics', value: 'databases', sortable: false, align: 'left'},
                    {text: 'Created on', value: 'created', sortable: false, align: 'right'},
                    {text: '', value: 'opt', sortable: false, align: 'right'},
                ],
            }
        },
        computed: {
            productName() {
              return this.appInfo.product_name
            },
            ...mapGetters('application', ['getServiceId']),
            ...mapState('application', ['users' ,'appInfo'])
        },
        methods: {
            formatDate,
            toDbDetail(database) {
                if (database.name) {
                    const Db =  findComponentDownward(this.$parent,'Databases')
                    Db.$refs.DBdetail.open(database)
                }
            },
            getData(flag){
                this.loading = true
                this.getDataBaseUserList(flag).then(() => {
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },
            updateUser({item,password}) {
                return this.$http.put(`/db-users/${item.uuid}/`,{
                    password
                }).then(()=> {
                    this.getDataBaseList(true)
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            addUser({name, password, database,permission}) {
                let ids = permission==='Super'?[] :database.map( o=> o.uuid)
                return this.$http.post('/db-users/', {
                    name,
                    password,
                    permission,
                    databases: ids,
                    service_uuid: this.getServiceId(this.appInfo)
                }).then(() => {
                    this.$message.success('User was created successfully.')
                    this.getDataBaseList(true)
                    this.getData(true)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            deleteUser({item}) {
                return this.$http.delete(`/db-users/${item.uuid}/`).then(() => {
                    this.getDataBaseList(true)
                    this.$message.success('The user was deleted.')
                    this.getData(true)
                }).catch((e) => {
                    // this.$message.error('Failed to perform this task. Please contact our support team')
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            ...mapActions('application', ['getDataBaseList','getDataBaseUserList'])
        },
        created(){
            this.getData()
        }
    }
</script>

<style lang="scss">

</style>
