<template>
    <Drawer class="detail-drawer" :display.sync="openDetail" width="1000">
        <div slot="title" class="title-wrapper pl-20">
            <span class="font-weight-bold">Synchromize Application Data</span>
            <v-spacer></v-spacer>
        </div>
        <v-layout column class="ma-0">
            <v-flex class=" pl-20" v-if="!forbidden">
                <p class="tips">Are you sure you want to synchronize the following data to the Control Panel?</p>
            </v-flex>
            <v-flex v-else>
            <p>No data is available for synchronization.</p>
            </v-flex>
            <v-flex>
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :loading="loading"
                        hide-actions
                        class="elevation-1">
                    <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                    <template v-slot:items="{item}">
                        <td class="text-xs-left">{{item.database}}</td>
                        <td class="text-xs-left">
                            <template v-for="(it,index) in item.users">
                                <v-chip small  outline  color="primary" :key="index">{{it}}</v-chip>
                            </template>
                        </td>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
        <div slot="actions" class="actions-wrapper">
            <v-btn class="text-none" color="blue darken-1" flat @click="openDetail= false" :disabled="loading">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" small :loading="loading"  @click="syncData" :disabled ="forbidden" >OK</v-btn>
        </div>
    </Drawer>
</template>

<script>
import Drawer from '@/components/Drawer.vue'
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    name: "DetailDrawer",
    components: {Drawer},
    data() {
        return {
            detail: {},
            openDetail: false,
            loading: false,
            comments:[],
            databaseExist: [],
            headers: [
                {text: 'Databases', value: 'database', sortable: false, align: 'left'},
                {text: 'Users', value: 'users', sortable: false, align: 'left'},
            ],
            items:[],
            forbidden: false
        }
    },

  computed: {
    ...mapGetters('application', ['getServiceId']),
    ...mapState('application', ['appInfo','users'])
  },
    methods: {
        open(items) {
            this.openDetail = true
            this.items = items
          this.getDataBaseList().then(res => {
            this.databaseExist = res.results
            if(this.items.length === 0 &&this.databaseExist.length === 0 &&this.users.length === 0){
              this.forbidden = true
            } else {
              this.forbidden = false
            }
          })
        },
        syncData(){
          this.loading = true
          let service_uuid = this.getServiceId(this.appInfo)
          return this.$http.put(`/databases/data-sync/?service_uuid=${service_uuid}`).then(res => {
            this.loading = false
            this.openDetail = false
            this.$message.success(res)
            this.getDataBaseList(true);
            this.getDataBaseUserList(true)
          }).catch(e => {
            this.$message.error(e.detail)
            return Promise.reject(e)
          })
        },
      ...mapActions('application', ['getDataBaseList', 'getDataBaseUserList'])
    },
    mounted() {


    }
}
</script>

<style lang="scss" scoped>
    .detail-drawer{
        .title-wrapper , .actions-wrapper{
            padding: 0px 10px;
            font-size: 18px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .tips{
            color: #3f51b5;
            margin-top: 16px;
        }
        .layout{
            margin: 0;
        }
    }

</style>
