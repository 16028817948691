<template>
  <tab-card title="Synchronization">
    <v-layout column>
      <v-flex>
        <p class="fs-3 font-weight-regular" style="color: rgb(102, 102, 102);">
          Synchronization will update the latest database, user, and permission data to the Control Panel.
        </p>
      </v-flex>
      <v-flex style="padding:0px">
        <v-btn class="text-none" color="primary" @click="syncHandle()" :loading="loading">Sync</v-btn>
      </v-flex>
    </v-layout>
    <DetailDrawer ref="sync" :existData="exist">
    </DetailDrawer>
  </tab-card>
</template>
<script>
import TabCard from "../../../../components/TabCard";
import DetailDrawer from "./_source/DetailDrawer";
import { mapGetters, mapState} from 'vuex'

export default {
  data() {
    return {
      loading: false
    }
  },
  components: {TabCard, DetailDrawer},
  computed: {
    exist(){
      return this.users.length && this.databasesValue
    },
    ...mapGetters('application', ['getServiceId']),
    ...mapState('application', ['users','appInfo'])
  },
  methods: {
    syncHandle() {
      this.loading = true;
      let service_uuid = this.getServiceId(this.appInfo)
      return this.$http.get(`/databases/source-data/?service_uuid=${service_uuid}`).then(res => {
        this.loading = false
        this.$refs.sync.open(res)
      }).catch(e => {
        this.loading = false
        this.$message.error(e.detail)
        return Promise.reject(e)
      })

    }
  }
}
</script>