<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="title" v-text="formData.action==='update'?'Update Accessible Topic':'Add Accessible Topic'"></span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid pa-0 grid-list-lg>
                        <v-flex xs12>
                            <v-text-field
                                    dense
                                    disabled
                                    return-object
                                    v-model="formData.name"
                                    lable="User"
                                    persistent-hint>
                            </v-text-field>
                        </v-flex>
                        <v-select
                                :disabled="formData.action==='update'"
                                dense
                                chips
                                multiple
                                deletable-chips
                                item-text="name"
                                item-value="uuid"
                                v-model="formData.databases"
                                :rules="[rules.arrayRequired]"
                                :items="formData.action==='update'?dataBases:databasesList"
                                persistent-hint
                                hint="Please choose the topic that you would like the user to have access to.">
                            <template v-slot:label>
                                <RequiredDot>Topic</RequiredDot>
                            </template>
                        </v-select>
                        <v-flex xs12>
                            <v-select
                                    v-model="formData.permission"
                                    :rules="[rules.required]"
                                    :items="['Produce','Consume','Produce and Consume']">
                                <template v-slot:label>
                                    <RequiredDot>Permission</RequiredDot>
                                </template>
                            </v-select>
                        </v-flex>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    import {mapState } from 'vuex' //mapState  mapGetters mapActions mapGetters


    export default {
        name: "AddUserDatabase",
        mixins: [loadingMixin],
        components: {RequiredDot},
        props : ['dbs','user'],
        data() {
            return {
                rules: {
                    same: value => value===this.formData.password||'Passwords do not match.',
                    ...rules,
                },
                formData: {
                    name:'',
                    permission:'',
                    databases: [],
                    action:''
                },
            }
        },
        computed: {
            databasesList(){
                return this.dataBases.filter((database) => {
                    let flag = true
                    this.dbs.forEach( db=>{
                        if (db.name === database.name || database.status==='SqlRemove') {
                            flag = false
                        }
                    })
                    return flag
                })
            },
            ...mapState('application', ['dataBases'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.formData = {
                        name:'',
                        permission:'',
                        databases: [],
                    }
                }
            }

        },
        methods: {
            $validator(){
                return this.$refs.form.validate()
            },
            open(action,item) {
                this.formData.action= action
                this.formData.name = this.user.name
                if (action==='update') {
                    this.formData.databases = [item.db_uuid]
                    this.formData.permission = item.permission
                }
                this.dialog = true
            }
        },
    }
</script>

<style lang="scss">

</style>
