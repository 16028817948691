<template>
    <div class="drawer">
        <div :class="maskClass" @click="closeByMask"></div>
        <div :class="mainClass" :style="mainStyle" class="main">
            <v-card class="drawer-main-card" :class="cardClass">
                <v-card-title v-if="$slots.title">
                    <slot name="title"></slot>
                </v-card-title>
                <div class="cnt ">
                    <slot></slot>
                </div>
                <v-card-actions v-if="$slots.actions">
                    <slot name="actions"></slot>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'Drawer',
        props: {
            display: {
                type: Boolean
            },
            mask: {
                type: Boolean,
                default: true
            },

            maskClosable: {
                type: Boolean,
                default: true
            },

            width: {
                type: Number,
                default: 400
            },
            inner: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            maskClass: function () {
                return {
                    'mask-show': (this.mask && this.display),
                    'mask-hide': !(this.mask && this.display),
                    'inner': this.inner
                }
            },
            mainClass: function () {
                return {
                    'main-show': this.display,
                    'main-hide': !this.display,
                    'inner': this.inner
                }
            },
            mainStyle: function () {
                let wd = document.body.clientWidth
                let width =  wd>=this.width?this.width: wd
                return {
                    width: width+'px',
                    right: this.display ? '0' : `-${width}px`,
                    borderLeft: this.mask ? 'none' : '1px solid #eee'
                }
            },
            cardClass : function(){
                return {
                    'p-t-50': !!this.$slots.title,
                    'p-b-50': !!this.$slots.actions,
                }
            }
        },
        mounted () {
            if (this.inner) {
                let box = this.$el.parentNode
                box.style.position = 'relative'
            }
        },
        methods: {
            closeByMask () {
                this.maskClosable && this.$emit('update:display', true)
            },
            closeByButton () {
                this.$emit('update:display', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .drawer {
        .mask-show {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background-color: rgba(0,0,0,.5);
            opacity: 1;
            transition: opacity .3s;
        }
        .mask-hide {
            opacity: 0;
            transition: opacity .3s;
        }

        .main {
            position: fixed;
            z-index: 10;
            top: 0;
            height: 100%;
            background: #fff;
            transition: all 0.3s;
            .drawer-main-card{
                position: relative;
                height: 100%;
                padding-left: 0;
                padding-right: 0;
                &.p-t-50{
                    padding-top: 50px;
                }
                &.p-b-50{
                    padding-bottom: 50px;
                }
                .v-card__title{
                    position: absolute;
                    top:0;
                    width: 100%;
                    height: 50px;
                    padding: 0;
                    line-height: 50px;
                    border-bottom: 1px solid  rgba(0,0,0,.12);
                }
                .v-card__actions{
                    height: 50px;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 50px;
                    border-top: 1px solid  rgba(0,0,0,.12);
                }
                .cnt{
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                }
            }
        }
        .main-show {
            opacity: 1;
        }
        .main-hide {
            opacity: 0;
        }

        .inner {
            position: absolute;
        }

        .drawer-head {
            display: flex;
            justify-content: space-between;
            height: 45px;
            line-height: 45px;
            padding: 0 15px;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #eee;
            .close-btn {
                display: inline-block;
                cursor: pointer;
                height: 100%;
                padding-left: 20px;
            }
        }
        .drawer-body {
            font-size: 14px;
            padding: 15px;
        }
    }
</style>
