var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-metrics pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _vm.productName === "oracle"
            ? [
                _c("v-flex", [_c("OracleDatabases")], 1),
                _c("v-flex", [_c("OracleUsers")], 1)
              ]
            : _vm.productName === "kafka"
            ? [
                _c("v-flex", [_c("KafkaDatabases")], 1),
                _c("v-flex", [_c("KafkaUsers")], 1)
              ]
            : [
                _c("v-flex", [_c("Databases")], 1),
                _c("v-flex", [_c("Users")], 1)
              ],
          ["sql server", "mysql"].indexOf(_vm.productName) !== -1
            ? _c("v-flex", [_c("DataSync")], 1)
            : _vm._e(),
          _c(
            "v-flex",
            [
              _c(
                "v-card",
                { attrs: { elevation: "0" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("Help", {
                        attrs: {
                          mod: ["menu_tab_db_user"],
                          product: _vm.appInfo.product_name
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }