<template>
    <tab-card title="Topic">
        <v-layout column>
            <v-flex>
                <v-data-table
                        :headers="headers"
                        :items="currentDataBases"
                        :loading="loading"
                        hide-actions
                        class="elevation-1">
                    <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                    <template v-slot:items="{item}">
                        <td>
                            <a v-if="item.status!=='SqlRemove'" style="text-decoration: underline" @click="$refs.DBdetail.open(item)">{{ item.name }}</a>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{on}">
                                    <span v-on="on">{{item.name}}</span>
                                </template>
                                <span>The Topic has been destroyed.</span>
                            </v-tooltip>
                        </td>
                        <td class="text-xs-left">
                            <template  v-for="(it,index) in item.database_users" >
                                <v-chip small  :class="{'user-chip':!item.is_super}" v-if="it.status!=='SqlRemove'" :color="!it.is_super&&'primary'" :text-color="!it.is_super&&'#ffffff'" @click="toUserDetail(it)"
                                        :key="index">
                                    {{it.name}} {{it.is_super?'(Super)':''}}
                                </v-chip>
                            </template>
                        </td>
                        <td class="text-xs-right">{{item.created | formatDate}}</td>
                        <td class="text-xs-right">
                                <span style="display:inline-block;width: 44px;">
                                    <v-menu transition="slide-x-transition">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon small v-on="on">
                                                <v-icon small>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-tile @click="$refs.delDB.open({ item:item})">
                                                <v-list-tile-title>Delete</v-list-tile-title>
                                            </v-list-tile>
                                        </v-list>
                                    </v-menu>
                                </span>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <div style="min-height: 192px;position: relative;">
                            <Spin v-show="loading"></Spin>
                            <Empty v-show="!loading"></Empty>
                        </div>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex style="text-align: center" v-if="dataBases.length>10">
                <v-pagination
                        total-visible="5"
                        v-model="page"
                        :length="Math.ceil(dataBases.length/10)"
                ></v-pagination>
            </v-flex>
            <v-flex style="padding : 20px 0 0;">
                <v-btn id="create-db"
                       :title="`Create a topic so you can start working on your Kafka.`" class="text-none"
                       color="primary" @click="$refs.addDB.open()"> Create Topic
                </v-btn>
            </v-flex>
        </v-layout>
        <Confirm ref="delDB" @confirm="deleteDatabase">
            <span slot="title">Delete Topic</span>
            <template v-slot:content="{options}">
                <span v-if="options.item&& options.item.name">This action is undoable. Are you sure you want to delete topic <b> {{options.item.name}}</b>?</span>
            </template>
        </Confirm>
        <AddDB ref="addDB" @confirm="addDatabase"></AddDB>
        <DbDetail ref="DBdetail"></DbDetail>
    </tab-card>
</template>

<script>
    import AddDB from '../_source/AddDB.vue'
    import DbDetail from './DbDetail.vue'
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import Confirm from '@/components/Confirm.vue'
    import {mapActions, mapState, mapGetters} from 'vuex'
    import {findComponentDownward} from '@/module/utils/assist.js'
    import TabCard from "@/components/TabCard"; //mapState  mapGetters


    export default {
        name: "KDatabases",
        components: {TabCard, AddDB, Confirm, Spin, Empty, DbDetail},
        data() {
            return {
                loading: false,
                page: 1,
                headers: [
                    {text: 'Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Connectable Users', value: 'db_users', sortable: false, align: 'left',},
                    {text: 'Created on', value: 'created', sortable: false, align: 'right',},
                    {text: '', value: 'opt', sortable: false, align: 'right',},
                ],
            }
        },
        computed: {
            productName() {
                return this.appInfo.product_name.toLowerCase()
            },
            currentDataBases() {
                return this.dataBases.length ? this.dataBases.filter((item, index) => {
                    return Math.ceil((index + 1) / 10) === this.page
                }) : []
            },
            ...mapGetters('application', ['getServiceId']),
            ...mapState('application', ['dataBases', 'appInfo'])
        },
        methods: {
            getData(flag) {
                this.loading = true
                this.getDataBaseList(flag).then(() => {
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },
            toUserDetail(user) {
                if (!user.is_super) {
                    const DbUser = findComponentDownward(this.$parent, 'DBUsers')
                    DbUser.$refs.userDetail.open(user)
                }
            },
            addDatabase({name}) {
                return this.$http.post('/databases/', {
                    name,
                    service_uuid: this.getServiceId(this.appInfo)
                }).then(() => {
                    this.$message.success('Your topic was created successfully !')
                    this.getData(true)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            deleteDatabase({item}) {
                return this.$http.delete(`/databases/${item.uuid}/`).then(() => {
                    this.$message.success(`Your topic was deleted successfully.`)
                    this.getData(true)
                    this.getDataBaseUserList(true)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            ...mapActions('application', ['getDataBaseList','getDataBaseUserList']),
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style lang="scss">
    .user-chip{
        &:hover{

        }
    }
</style>
