<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form  v-model="valid" ref="form" lazy-validation v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="title">New User</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid pa-0 grid-list-lg>
                        <v-text-field
                                v-model="formData.name"
                                :rules="[rules.required]"
                                :height="30"
                                required
                                clearable>
                            <template v-slot:label>
                                <RequiredDot>Name</RequiredDot>
                            </template>
                        </v-text-field>
                        <v-layout wrap>
                            <template v-if="productName!=='redis'">
                                <v-flex xs12 >
                                    <v-select
                                            :hide-details="formData.permission!=='Super'"
                                            :rules="[rules.required,rules.noNumOnly]"
                                            v-model="formData.permission"
                                            :items="['Super','Read-Write','Read-Only']">
                                        <template v-slot:label>
                                            <RequiredDot>Permission</RequiredDot>
                                        </template>
                                    </v-select>
                                </v-flex>
                                <v-flex v-show="formData.permission==='Super'" style="font-size: 14px;height: 77px;"  class="orange--text text--darken-3">
                                    <v-icon color="orange darken-3" small>warning</v-icon> Please do not change the default administrator password using super users, otherwise some application management features will be limited.
                                </v-flex>
                                <v-flex xs12 v-if="formData.permission !== 'Super'">
                                    <v-select
                                            dense
                                            deletable-chips
                                            return-object
                                            item-text="name"
                                            v-model="formData.database"
                                            :rules="[rules.arrayRequired]"
                                            :items="existDBs"
                                            chips
                                            multiple
                                            persistent-hint
                                            hint="Database the user will have permission to access">
                                        <template v-slot:label>
                                            <RequiredDot>Database</RequiredDot>
                                        </template>
                                    </v-select>
                                </v-flex>
                            </template>
                            <v-flex xs6 v-if="showPassword">
                                <v-text-field
                                        v-model="formData.password"
                                        :rules="pwdRules"
                                        type="password"
                                        persistent-hint
                                >
                                    <!--hint="Password must contain at least 7 characters , one lower character , one upper character, one number and one special character."-->
                                    <template v-slot:label>
                                        <RequiredDot>Password</RequiredDot>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs6 v-if="showPassword">
                                <v-text-field
                                        v-model="formData.passwordConfirm"
                                        :rules="[rules.required ,rules.same]"
                                        type="password">
                                    <template v-slot:label>
                                        <RequiredDot>Confirm Password</RequiredDot>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    import {mapState} from 'vuex' //mapState  mapGetters mapActions mapGetters


    export default {
        name: "AddDBUser",
        mixins: [loadingMixin],
        components: {RequiredDot},
        data() {
            return {
                rules: {
                    same: value => value === this.formData.password || 'Passwords do not match.',
                    ...rules,
                },
                formData: {
                    name: '',
                    permission:'',
                    database: [],
                    password: '',
                    passwordConfirm: ''
                }
            }
        },
        props: {
            showPassword: {
                type: Boolean,
                default: false,
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.formData = {
                        name: '',
                        permission:'',
                        database: [],
                        password: '',
                        passwordConfirm: ''
                    }
                }
            }
        },
        computed: {
            productName() {
                return this.appInfo.product_name.toLowerCase()
            },
            existDBs() {
                return this.dataBases.filter((item)=>{
                    return  item.status!=='SqlRemove'
                })
            },
            pwdRules() {
                let arr = [this.rules.required,this.rules.moreCounter]
                this.productName=== 'sql server' && arr.push(this.rules.n_c_C)
                return  arr
            },
            ...mapState('application', ['dataBases', 'appInfo'])
        },
        methods: {
            $validator() {
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">

</style>
