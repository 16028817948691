<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="title">Change Permission</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid pa-0 grid-list-lg>
                        <v-flex xs12>
                            <v-select
                                    v-model="formData.permission"
                                    :rules="[rules.required]"
                                    :items="['Read-Write','Read-Only']">
                                <template v-slot:label>
                                    <RequiredDot>Permission</RequiredDot>
                                </template>
                            </v-select>
                        </v-flex>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    // import {mapState} from 'vuex' //mapState  mapGetters mapActions mapGetters


    export default {
        name: "ChangeRelations",
        mixins: [loadingMixin],
        components: {RequiredDot},
        props: ['user'],
        data() {
            return {
                rules,
                formData: {
                    uuid: '',
                    permission: '',
                },
            }
        },
        // computed: {
        //     databasesList() {
        //         return this.dataBases.filter((database) => {
        //             let flag = true
        //             debugger
        //             this.formData.databases.forEach(db => {
        //                 if (db.name === database.name || database.status === 'SqlRemove') {
        //                     flag = false
        //                 }
        //             })
        //             return flag
        //         })
        //     },
        //     ...mapState('application', ['dataBases'])
        // },
        watch: {
            dialog(val) {
                if (!val) {
                    this.formData = {
                        name: '',
                        permission: '',
                    }
                }
            }

        },
        methods: {
            $validator() {
                return this.$refs.form.validate()
            },
            open({uuid,permission}) {
                debugger
                this.formData.uuid = uuid
                this.formData.permission = permission
                this.dialog = true
            }
        },
    }
</script>

<style lang="scss">

</style>
