var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tab-card",
    { attrs: { title: "Tablespaces" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-data-table",
                {
                  staticClass: "elevation-1",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.currentDataBases,
                    loading: _vm.loading,
                    "hide-actions": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("td", [
                            item.status !== "SqlRemove"
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      "text-decoration": "underline"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.DBdetail.open(item)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              : _c("span", [_vm._v(_vm._s(item.name))])
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-xs-left" },
                            [
                              _vm._l(item.database_users, function(it, index) {
                                return [
                                  it.status !== "SqlRemove"
                                    ? _c(
                                        "v-chip",
                                        {
                                          key: index,
                                          class: {
                                            "user-chip": !item.is_super
                                          },
                                          attrs: {
                                            small: "",
                                            color: !it.is_super && "primary",
                                            "text-color":
                                              !it.is_super && "#ffffff"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toUserDetail(it)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(it.name) +
                                              " " +
                                              _vm._s(
                                                it.is_super ? "(Super)" : ""
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          ),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm._f("formatDate")(item.created)))
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "44px"
                                }
                              },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { transition: "slide-x-transition" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      small: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("more_vert")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-tile",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.delDB.open({
                                                  item: item
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v("Delete")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "min-height": "192px",
                                position: "relative"
                              }
                            },
                            [
                              _c("Spin", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ]
                              }),
                              _c("Empty", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.loading,
                                    expression: "!loading"
                                  }
                                ]
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-progress-linear", {
                    attrs: { color: "blue", indeterminate: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "progress",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.dataBases.length > 10
            ? _c(
                "v-flex",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("v-pagination", {
                    attrs: {
                      "total-visible": "5",
                      length: Math.ceil(_vm.dataBases.length / 10)
                    },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { staticStyle: { padding: "20px 0 0" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: {
                    title:
                      "Create a tablespace so you can start working on your tablespace.",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addDB.open()
                    }
                  }
                },
                [_vm._v(" Create Tablespace\n            ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Confirm",
        {
          ref: "delDB",
          on: { confirm: _vm.deleteDatabase },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function(ref) {
                var options = ref.options
                return [
                  options.item && options.item.name
                    ? _c("span", [
                        _vm._v(
                          "This action is undoable. Are you sure you want to delete tablespace "
                        ),
                        _c("b", [_vm._v(" " + _vm._s(options.item.name))]),
                        _vm._v("?")
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Delete Tablespace")
          ])
        ]
      ),
      _c("AddDB", { ref: "addDB", on: { confirm: _vm.addDatabase } }),
      _c("DbDetail", { ref: "DBdetail" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }