var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        {
          directives: [
            {
              name: "insert",
              rawName: "v-insert",
              value: {
                tagName: "input",
                attrs: { style: "display:none", type: "text" }
              },
              expression:
                "{tagName:'input',attrs:{style:'display:none',type:'text'}}"
            }
          ],
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "span",
                  { staticClass: "title" },
                  [_vm._t("default", [_vm._v("Create Topic")])],
                  2
                )
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "pa-0": "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [
                            function(v) {
                              return (
                                (!!v && !!v.replace(/(^\s*)|(\s*$)/g, "")) ||
                                "Name is required"
                              )
                            }
                          ],
                          height: 30,
                          required: "",
                          clearable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [_c("RequiredDot", [_vm._v("Name")])]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      }),
                      _vm.characterSet &&
                      _vm.appInfo.product_name.toLowerCase() !== "sql server"
                        ? _c(
                            "v-layout",
                            { staticClass: "mt-2", attrs: { column: "" } },
                            [
                              _c("v-flex", { staticClass: "mb-2" }, [
                                _c(
                                  "span",
                                  { staticStyle: { color: "rgba(0,0,0,.54)" } },
                                  [
                                    _c("RequiredDot", [_vm._v("Character Set")])
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "mt-0",
                                      attrs: {
                                        row: "",
                                        rules: [_vm.rules.required]
                                      },
                                      model: {
                                        value: _vm.formData.character_set,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "character_set",
                                            $$v
                                          )
                                        },
                                        expression: "formData.character_set"
                                      }
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "latin1",
                                          color: "primary",
                                          value: "latin1"
                                        }
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "utf8",
                                          color: "primary",
                                          value: "utf8"
                                        }
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "gbk",
                                          color: "primary",
                                          value: "gbk"
                                        }
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "utf8mb4",
                                          color: "primary",
                                          value: "utf8mb4"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.close }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "primary", loading: _vm.loading },
                      on: { click: _vm.ok }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }