<template>
    <tab-card :title="`Databases${productName === 'cassandra'?'(Keyspace)':''}`">
        <v-layout column>
            <v-flex>
                <v-data-table
                        :headers="productName==='redis'?RdHeaders:headers"
                        :items="currentDataBases"
                        :loading="loading"
                        hide-actions
                        class="elevation-1">
                    <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                    <template v-slot:items="{item}">
                        <template v-if="productName!=='redis'">
                            <td>
                                <a v-if="item.status!=='SqlRemove'" style="text-decoration: underline" @click="$refs.DBdetail.open(item)">{{ item.name }}</a>
                                <v-tooltip top v-else>
                                    <template v-slot:activator="{on}">
                                        <span v-on="on">{{item.name}}</span>
                                    </template>
                                    <span>The database has been destroyed.</span>
                                </v-tooltip>
                            </td>
                            <td class="text-xs-left">
                                <template  v-for="(it,index) in item.database_users" >
                                    <v-chip small  :class="{'user-chip':!(it.is_super||it.is_other)}" v-if="it.status!=='SqlRemove'" :color="!(it.is_super||it.is_other)&&'primary'" :text-color="!(it.is_super||it.is_other)&&'#ffffff'" @click="toUserDetail(it)"
                                            :key="index">
                                        {{it.name}} {{it.is_super?'(Super)':it.is_other?'(Others)':''}}
                                    </v-chip>
                                </template>
                            </td>
                            <td class="text-xs-right">{{item.created | formatDate}}</td>
                            <td class="text-xs-right">
                                <span style="display:inline-block;width: 44px;">
                                    <v-menu transition="slide-x-transition">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon small v-on="on">
                                                <v-icon small>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-tile @click="$refs.delDB.open({ item:item})">
                                                <v-list-tile-title>Delete</v-list-tile-title>
                                            </v-list-tile>
                                        </v-list>
                                    </v-menu>
                                </span>
                            </td>
                        </template>
                        <template v-else>
                            <td class="text-xs-left">{{item.name}}</td>
                            <td class="text-xs-center">{{ `${item.keys || '--'}` }}</td>
                        </template>
                    </template>
                    <template v-slot:no-data>
                        <div style="min-height: 192px;position: relative;">
                            <Spin v-show="loading"></Spin>
                            <Empty v-show="!loading">
                                <span v-if="productName==='redis'">By default, Redis has 0-15 indexes for databases.
                                 <br/>You don't need to create a database in Redis, and you can use the SELECT command to select the database you want to work on.
                                </span>
                            </Empty>
                        </div>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex style="text-align: center" v-if="dataBases.length>10">
                <v-pagination
                        total-visible="5"
                        v-model="page"
                        :length="Math.ceil(dataBases.length/10)"
                ></v-pagination>
            </v-flex>
            <v-flex style="padding : 20px 0 0;">
                <v-btn id="create-db"
                       v-if="productName!=='redis'"
                       :title="`Create a database so you can start working on your database.`" class="text-none"
                       color="primary" @click="$refs.addDB.open()"> Create Database
                </v-btn>

            </v-flex>
        </v-layout>
        <Confirm ref="delDB" @confirm="deleteDatabase">
            <span slot="title">Delete Database</span>
            <template v-slot:content="{options}">
                <span v-if="options.item&& options.item.name">This action is undoable. Are you sure you want to delete database <b> {{options.item.name}}</b>?</span>
            </template>
        </Confirm>
        <AddDB ref="addDB" @confirm="addDatabase" :character-set="needCharacterSet"></AddDB>
        <DbDetail ref="DBdetail"></DbDetail>
    </tab-card>
</template>

<script>
    import AddDB from '../_source/AddDB.vue'
    import DbDetail from './DbDetail.vue'
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import Confirm from '@/components/Confirm.vue'
    import {mapActions, mapState, mapGetters} from 'vuex'
    import {findComponentDownward} from '@/module/utils/assist.js'
    import TabCard from "@/components/TabCard"; //mapState  mapGetters

    const character_set_arr = ['cockroachdb', 'mongodb', 'cassandra'];

    export default {
        name: "Databases",
        components: {TabCard, AddDB, Confirm, Spin, Empty, DbDetail},
        data() {
            return {
                loading: false,
                page: 1,
                headers: [
                    {text: 'Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Connectable Users', value: 'db_users', sortable: false, align: 'left',},
                    {text: 'Created on', value: 'created', sortable: false, align: 'right',},
                    {text: '', value: 'opt', sortable: false, align: 'right',},
                ],
                RdHeaders: [
                    {text: 'Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Size', value: 'key', sortable: false, align: 'center',}
                ]
            }
        },
        computed: {
            productName() {
                return this.appInfo.product_name.toLowerCase()
            },
            currentDataBases() {
                return this.dataBases.length ? this.dataBases.filter((item, index) => {
                    return Math.ceil((index + 1) / 10) === this.page
                }) : []
            },
            needCharacterSet() {
                return character_set_arr.indexOf(this.productName) === -1
            },
            ...mapGetters('application', ['getServiceId']),
            ...mapState('application', ['dataBases', 'appInfo'])
        },
        methods: {
            getData(flag) {
                this.loading = true
                this.getDataBaseList(flag).then(() => {
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },
            toUserDetail(user) {
                if (!(user.is_super||user.is_other)) {
                    const DbUser = findComponentDownward(this.$parent, 'DBUsers')
                    DbUser.$refs.userDetail.open(user)
                }
            },
            addDatabase({name, character_set}) {
                return this.$http.post('/databases/', {
                    name,
                    character_set: character_set,
                    service_uuid: this.getServiceId(this.appInfo)
                }).then(() => {
                    this.$message.success('Your database was created successfully !')
                    this.getData(true)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            deleteDatabase({item}) {
                return this.$http.delete(`/databases/${item.uuid}/`).then(() => {
                    this.$message.success(`Your database was deleted successfully.`)
                    this.getData(true)
                    this.getDataBaseUserList(true)
                }).catch((e) => {
                    // this.$message.error(`Oops, it failed to delete the database, please contact us at support@cloudclusters.io for this issue.`)
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            ...mapActions('application', ['getDataBaseList','getDataBaseUserList']),
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style lang="scss">
    .user-chip{
        &:hover{

        }
    }
</style>
