<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="title" >Change Tablespace</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid pa-0 grid-list-lg>
                        <v-select
                                dense
                                item-text="name"
                                item-value="uuid"
                                v-model="database"
                                :rules="[rules.required]"
                                :items="databasesList">
                            <template v-slot:label>
                                <RequiredDot>Tablespace</RequiredDot>
                            </template>
                        </v-select>
<!--                        <v-flex xs12>-->
<!--                            <v-select-->
<!--                                    v-model="formData.permission"-->
<!--                                    :rules="[rules.required]"-->
<!--                                    :items="['Read-Write','Read-Only']">-->
<!--                                <template v-slot:label>-->
<!--                                    <RequiredDot>Permission</RequiredDot>-->
<!--                                </template>-->
<!--                            </v-select>-->
<!--                        </v-flex>-->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    import {mapState } from 'vuex' //mapState  mapGetters mapActions mapGetters


    export default {
        name: "AddUserDatabase",
        mixins: [loadingMixin],
        components: {RequiredDot},
        data() {
            return {
                rules,
                formData: {
                    uuid:'',
                    databases: [],
                },
                currentDatabase:{}
            }
        },
        computed: {
            databasesList(){
                return this.dataBases.filter((database) => {
                    return database.name!==this.currentDatabase.name
                })
            },
            database:{
                get() {
                    return this.formData.databases[0]
                },
                set(v) {
                    this.formData.databases = [v]
                }
            },
            ...mapState('application', ['dataBases'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.formData = {
                        uuid:'',
                        databases: [],
                    }
                }
            }

        },
        methods: {
            $validator(){
                return this.$refs.form.validate()
            },
            open({uuid,databases}) {
                this.formData.uuid = uuid
                this.currentDatabase = databases[0]?databases[0]:{}
                this.dialog = true
            }
        },
    }
</script>

<style lang="scss">

</style>
