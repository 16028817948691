var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tab-card",
    { attrs: { title: "Users" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-data-table",
                {
                  staticClass: "elevation-1",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.users,
                    loading: _vm.loading,
                    "hide-actions": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            [
                              item.databases[0] !== "all" &&
                              item.status === "Active" &&
                              _vm.productName !== "Redis"
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.userDetail.open(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                : _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        top: "",
                                        disabled: item.status !== "SqlRemove"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c("span", _vm._g({}, on), [
                                                  _vm._v(_vm._s(item.name))
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("The user has been deleted.")
                                      ])
                                    ]
                                  )
                            ],
                            1
                          ),
                          _vm.productName !== "Redis"
                            ? _c(
                                "td",
                                { staticClass: "text-xs-left" },
                                [
                                  _vm._l(item.databases, function(it, index) {
                                    return [
                                      it.status !== "SqlRemove"
                                        ? _c(
                                            "v-chip",
                                            {
                                              key: index,
                                              attrs: {
                                                small: "",
                                                color: it.name && "primary",
                                                "text-color":
                                                  it.name && "#ffffff"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toDbDetail(it)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(it.name ? it.name : it)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm._f("formatDate")(item.created)))
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "44px"
                                }
                              },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { transition: "slide-x-transition" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      small: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("more_vert")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        item.status !== "SqlRemove"
                                          ? _c(
                                              "v-list-tile",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$refs.userm.open(
                                                      { item: item }
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-list-tile-title", [
                                                  _vm._v("Reset Password")
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-list-tile",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.delUser.open({
                                                  item: item
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v("Delete")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "min-height": "192px",
                                position: "relative"
                              }
                            },
                            [
                              _c("Spin", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ]
                              }),
                              _c("Empty", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.loading,
                                    expression: "!loading"
                                  }
                                ]
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-progress-linear", {
                    attrs: { color: "blue", indeterminate: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "progress",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticStyle: { padding: "30px 0 0" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: {
                    id: "create-user",
                    title:
                      "Create a user so you can start working on your topic.",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addUser.open()
                    }
                  }
                },
                [_vm._v(" Create User")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AddDBUser", {
        ref: "addUser",
        attrs: { "show-password": "" },
        on: { confirm: _vm.addUser }
      }),
      _c("UserManage", { ref: "userm", on: { confirm: _vm.updateUser } }),
      _c("UserDetail", { ref: "userDetail" }),
      _c(
        "Confirm",
        {
          ref: "delUser",
          on: { confirm: _vm.deleteUser },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function(ref) {
                var options = ref.options
                return [
                  options.item && options.item.name
                    ? _c("span", [
                        _vm._v("Are you sure you want to delete user "),
                        _c("b", [_vm._v(" " + _vm._s(options.item.name))]),
                        _vm._v("?")
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Delete  User")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }