var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tab-card",
    { attrs: { title: "Synchronization" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", [
            _c(
              "p",
              {
                staticClass: "fs-3 font-weight-regular",
                staticStyle: { color: "rgb(102, 102, 102)" }
              },
              [
                _vm._v(
                  "\n        Synchronization will update the latest database, user, and permission data to the Control Panel.\n      "
                )
              ]
            )
          ]),
          _c(
            "v-flex",
            { staticStyle: { padding: "0px" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { color: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.syncHandle()
                    }
                  }
                },
                [_vm._v("Sync")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("DetailDrawer", { ref: "sync", attrs: { existData: _vm.exist } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }