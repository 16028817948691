<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" v-insert="{tagName:'input',attrs:{style:'display:none',type:'text'}}" ref="form"
                lazy-validation>
            <v-card>
                <v-card-title>
                    <span class="title"><slot>Create Topic</slot></span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container pa-0>
                        <v-text-field
                                v-model="formData.name"
                                :rules="[v => !!v && !!v.replace(/(^\s*)|(\s*$)/g,'') || 'Name is required']"
                                :height="30"
                                required
                                clearable>
                            <template v-slot:label>
                                <RequiredDot>Name</RequiredDot>
                            </template>
                        </v-text-field>
                        <v-layout v-if="characterSet && appInfo.product_name.toLowerCase() !=='sql server'" column
                                  class="mt-2">
                            <v-flex class="mb-2">
                                <span style="color: rgba(0,0,0,.54)">
                                    <RequiredDot>Character Set</RequiredDot>
                                </span>
                            </v-flex>
                            <v-flex>
                                <v-radio-group
                                        row
                                        class="mt-0"
                                        v-model="formData.character_set"
                                        :rules="[rules.required]">
                                    <v-radio label="latin1" color="primary" value="latin1"></v-radio>
                                    <v-radio label="utf8" color="primary" value="utf8"></v-radio>
                                    <v-radio label="gbk" color="primary" value="gbk"></v-radio>
                                    <v-radio label="utf8mb4" color="primary" value="utf8mb4"></v-radio>
                                </v-radio-group>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    import {mapState} from 'vuex'

    export default {
        name: "AddDB",
        mixins: [loadingMixin],
        components: {RequiredDot},
        props: {
            characterSet: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                rules,
                formData: {
                    character_set: '',
                    name: '',
                }
            }
        },
        computed: {
            ...mapState('application', ['appInfo'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.formData.name = ''
                }
            }
        },
        methods: {
            open(options) {
                this.formData = Object.assign(this.formData, options);
                this.dialog = true
            },
            $validator() {
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">

</style>
