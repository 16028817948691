<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card >
            <v-toolbar dark color="primary"  height="48" fixed>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Database Details({{selected.name}})</v-toolbar-title>
            </v-toolbar>
            <v-container class="panel-wrapper" grid-list-xl>
                <v-layout column>
                    <v-flex mt-4>
                        <v-subheader class="panel-title">Users</v-subheader>
                    </v-flex>
                    <v-flex>
                        <v-data-table
                                :headers="headers"
                                :loading='loading'
                                :items="userList"
                                hide-actions
                                class="elevation-1">
                            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                            <template v-slot:items="{item}">
                                <td>
                                    <v-tooltip top :disabled="item.status!=='SqlRemove'">
                                        <template v-slot:activator="{on}">
                                            <span v-on="on">{{item.name}}</span>
                                        </template>
                                        <span>The user no longer exists.</span>
                                    </v-tooltip>
                                </td>
                                <td class="text-xs-right">{{item.permission}}</td>
<!--                                <td class="text-xs-right">-->
<!--                                  <span style="display:inline-block;width: 44px;" >-->
<!--                                    <v-menu transition="slide-x-transition">-->
<!--                                        <template v-slot:activator="{ on }">-->
<!--                                            <v-btn icon small v-on="on">-->
<!--                                                <v-icon small>more_vert</v-icon>-->
<!--                                            </v-btn>-->
<!--                                        </template>-->
<!--                                        <v-list>-->
<!--&lt;!&ndash;                                            <v-list-tile&ndash;&gt;-->
<!--&lt;!&ndash;                                                    v-if="item.status !== 'SqlRemove'"&ndash;&gt;-->
<!--&lt;!&ndash;                                                    @click="$refs.addUser.open('update',item)">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <v-list-tile-title>Update</v-list-tile-title>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </v-list-tile>&ndash;&gt;-->
<!--                                            <v-list-tile-->
<!--                                                    @click="$refs.delUser.open({content : `Are you sure you want to remove user ${item.name} from the database?`,item:item})">-->
<!--                                                <v-list-tile-title>Delete</v-list-tile-title>-->
<!--                                            </v-list-tile>-->
<!--                                        </v-list>-->
<!--                                    </v-menu>-->
<!--                                </span>-->
<!--                                </td>-->
                            </template>
                            <template v-slot:no-data>
                                <div style="min-height: 192px;position: relative;">
                                    <Spin v-show="loading"></Spin>
                                    <Empty v-show="!loading">
                                        <span>No user has permission</span>
                                    </Empty>
                                </div>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>
<!--                <Confirm ref="delUser" @confirm="deleteUser">-->
<!--                    <span slot="title">Delete Database User</span>-->
<!--                    <template v-slot:content="options"></template>-->
<!--                </Confirm>-->
                <v-divider></v-divider>
                <section class="pb-4">
                    <v-subheader class="panel-title">Statistics</v-subheader>
                    <v-layout column>
                        <v-flex>
                            <p style="font-size: 14px;color: #666;margin: 0;">
                                The following is result of <code>stats()</code>function
                            </p>
                        </v-flex>
                        <v-flex>
                            <v-data-table
                                    :headers="statsHeaders"
                                    :items="statsList"
                                    :loading="loading"
                                    hide-actions
                                    class="elevation-1">
                                <template v-slot:items="{item}">
                                    <td>{{ item.key }}</td>
                                    <td class="text-xs-right">{{ item.value }}</td>
                                    <td class="text-xs-right">{{ item.description }}</td>
                                </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </section>
                <v-divider></v-divider>
                <section class="pb-4">
                    <v-subheader class="panel-title">Connection Information</v-subheader>
                    <v-layout column>
                        <v-flex>
                            <p style="font-size: 14px;color: #666;margin: 0;">
                                Connecting to your {{appInfo.product_name}} instance requires to create databases and users first. You can do it on the <a @click="dialog = false">DB&User</a> page.
                                For more detailed information, you can refer to the <a target="_blank" :href="`https://cloudclusters.io/docs/oracle/`">{{appInfo.product_name}}'s document</a>.
                            </p>
                        </v-flex>
                    </v-layout>
                </section>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapState, mapGetters} from 'vuex' //mapState  mapGetters
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'

    export default {
        name: "DbDetail",
        components: {Empty,Spin},
        mixins: [loadingMixin],
        data() {
            return {
                loading: false,
                selected: '',
                statsList:[],
                did: '',
                headers: [
                    {text: 'Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Permission', value: 'permission', sortable: false, align: 'right'},
                ],
                statsHeaders: [
                    {text: 'Name', value: 'key', sortable: false, align: 'left'},
                    {text: 'Value', value: 'value', sortable: false, align: 'right'},
                    {text: 'Description', value: 'description', sortable: false, align: 'right'},
                ],
            }
        },
        computed: {
            userList() {
                return this.databaseDetail.db_user|| []
            },
            ...mapState('application', [ 'appInfo', 'users','databaseDetail']),
            ...mapGetters('application', ['getServiceId'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.did = ''
                    this.selected = ''
                    this.statsList = []
                    this.$store.commit('setState', {
                            module: 'application',
                            payload: {
                                databaseDetail:{}
                            }
                        }, { root: true }
                    );
                } else  {
                    this.loading = true
                    this.getDetail().then(() => {
                        this.loading = false
                        this.getStats()
                    })
                }
            }
        },
        methods: {
            open(item) {
                this.dialog = true
                this.did = item.uuid
                this.selected = item
            },
            getDetail() {
                return this.getDataBaseDetail(this.did)
            },
            getUserList() {
                this.loading = true
                this.getDetail().then(() => {
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },
            // deleteUser({item}) {
            //     return this.$http.put(`databases/${this.databaseDetail.uuid}/change-relation/`, {
            //         relation: 'DEL',
            //         users : [item.uuid],
            //     }).then(() => {
            //         this.$message.success(`The user was removed successfully.`)
            //         this.getUserList()
            //         this.getDataBaseList(true)
            //         this.getDataBaseUserList(true)
            //     }).catch((e) => {
            //         this.$message.error(e.detail)
            //         // this.$message.error(`Oops, it failed to delete the user, The database user's permission was modified successfully.`)
            //         return Promise.reject(e)
            //     })
            // },
            getStats() {
                this.$http.get(`/databases/${this.databaseDetail.uuid}/stats/`).then((res) => {
                    this.statsList = res.sort((a,b)=>{return a.key.localeCompare(b.key)})
                }).catch((e) => {
                    // this.$message.error('Oops, something bad happened while retrieving database information, please contact us at support@cloudclusters.io for this issue.')
                    this.$message.error(e.detail)
                })
            },
            ...mapActions('application', ['getDataBaseList', 'getDataBaseUserList', 'getDataBaseDetail']),
        },
        created() {
            this.getDataBaseUserList()
        }
    }
</script>

<style lang="scss">
    .panel-database-detail {
        .search-wrapper {
            .v-text-field {
                padding-top: 0;
            }

            .v-text-field--solo .v-input__control {
                min-height: 36px;
            }
        }
    }
</style>
