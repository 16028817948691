var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary", height: "48", fixed: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v("Topic Details(" + _vm._s(_vm.selected.name) + ")")
              ])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "panel-wrapper", attrs: { "grid-list-xl": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { "mt-4": "" } },
                    [
                      _c("v-subheader", { staticClass: "panel-title" }, [
                        _vm._v("Users")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-data-table",
                        {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.headers,
                            loading: _vm.loading,
                            items: _vm.userList,
                            "hide-actions": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "items",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            disabled:
                                              item.status !== "SqlRemove"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c("span", _vm._g({}, on), [
                                                      _vm._v(_vm._s(item.name))
                                                    ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("The user no longer exists.")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(item.permission))
                                  ]),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    item.permission !== "Super"
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "44px"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  transition:
                                                    "slide-x-transition"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "more_vert"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  [
                                                    item.status !== "SqlRemove"
                                                      ? _c(
                                                          "v-list-tile",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$refs.addUser.open(
                                                                  "update",
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [_vm._v("Update")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-list-tile",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.delUser.open(
                                                              {
                                                                content:
                                                                  "Are you sure you want to remove user " +
                                                                  item.name +
                                                                  " from the topic?",
                                                                item: item
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [_vm._v("Delete")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              }
                            },
                            {
                              key: "no-data",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "min-height": "192px",
                                        position: "relative"
                                      }
                                    },
                                    [
                                      _c("Spin", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.loading,
                                            expression: "loading"
                                          }
                                        ]
                                      }),
                                      _c(
                                        "Empty",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.loading,
                                              expression: "!loading"
                                            }
                                          ]
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("No user has permission")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { color: "blue", indeterminate: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "progress",
                                fn: function() {
                                  return undefined
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticStyle: { padding: "30px 0" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.addUser.open("add")
                            }
                          }
                        },
                        [_vm._v(" Add User")]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            position: "relative",
                            top: "12px",
                            "font-size": "13px",
                            color: "#666"
                          }
                        },
                        [_vm._v("Add existing users to this topic")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("RelevancyUser", {
                ref: "addUser",
                attrs: { databaseDetail: _vm.databaseDetail },
                on: { confirm: _vm.addUser }
              }),
              _c(
                "Confirm",
                {
                  ref: "delUser",
                  on: { confirm: _vm.deleteUser },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function(options) {
                        return undefined
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Delete Topic User")
                  ])
                ]
              ),
              _c("v-divider"),
              _c(
                "section",
                { staticClass: "pb-4" },
                [
                  _c("v-subheader", { staticClass: "panel-title" }, [
                    _vm._v("Statistics")
                  ]),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#666",
                              margin: "0"
                            }
                          },
                          [
                            _vm._v(
                              "\n                            The following is result of "
                            ),
                            _c("code", [_vm._v("stats()")]),
                            _vm._v("function\n                        ")
                          ]
                        )
                      ]),
                      _c(
                        "v-flex",
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.statsHeaders,
                              items: _vm.statsList,
                              "hide-actions": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "items",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("td", [_vm._v(_vm._s(item.key))]),
                                    _c("td", { staticClass: "text-xs-right" }, [
                                      _vm._v(_vm._s(item.value))
                                    ]),
                                    _c("td", { staticClass: "text-xs-right" }, [
                                      _vm._v(_vm._s(item.description))
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "section",
                { staticClass: "pb-4" },
                [
                  _c("v-subheader", { staticClass: "panel-title" }, [
                    _vm._v("Connection Information")
                  ]),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#666",
                              margin: "0"
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Connecting to your " +
                                _vm._s(_vm.appInfo.product_name) +
                                " instance requires to create topics and users first. You can do it on the "
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.dialog = false
                                  }
                                }
                              },
                              [_vm._v("Topic & User")]
                            ),
                            _vm._v(
                              " page.\n                            For more detailed information, you can refer to the "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href:
                                    "https://cloudclusters.io/docs/" +
                                    _vm.appInfo.product_name
                                      .toLowerCase()
                                      .replace(" ", "") +
                                    "/"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.appInfo.product_name) +
                                    "'s document"
                                )
                              ]
                            ),
                            _vm._v(".\n                        ")
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }