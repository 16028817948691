<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card >
            <v-toolbar dark color="primary"  height="48" fixed>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>User Details({{user.name}})</v-toolbar-title>
            </v-toolbar>
            <v-container class="panel-wrapper" grid-list-xl>
                <v-layout column>
                    <v-flex mt-4>
                        <v-subheader class="panel-title">Topics</v-subheader>
                    </v-flex>
                    <v-flex>
                        <v-data-table
                                :headers="headers"
                                :loading='loading'
                                :items="items"
                                hide-actions
                                class="elevation-1">
                            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                            <template v-slot:items="{item}">
                                <td>
                                    <v-tooltip top :disabled="item.status!=='SqlRemove'">
                                        <template v-slot:activator="{on}">
                                            <span v-on="on">{{item.name}}</span>
                                        </template>
                                        <span>The topic has been destroyed.</span>
                                    </v-tooltip></td>
                                <td class="text-xs-right">{{item.permission}}</td>
                                <td class="text-xs-right">
                                  <span style="display:inline-block;width: 44px;" v-if="item.permission !== 'Super'">
                                    <v-menu transition="slide-x-transition">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon small v-on="on">
                                                <v-icon small>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-tile
                                                    v-if="item.status!=='SqlRemove'"
                                                    @click="$refs.addDb.open('update',item)">
                                                <v-list-tile-title>
                                                    Update
                                                </v-list-tile-title>
                                            </v-list-tile>
                                            <v-list-tile
                                                    @click="$refs.delDatabase.open({content : `Are you sure you want to remove topic ${item.name} from the user?`,db:item})">
                                                <v-list-tile-title>Delete</v-list-tile-title>
                                            </v-list-tile>
                                        </v-list>
                                    </v-menu>
                                </span>
                                </td>
                            </template>
                            <template v-slot:no-data>
                                <div style="min-height: 192px;position: relative;">
                                    <Spin v-show="loading"></Spin>
                                    <Empty v-show="!loading">
                                        <span>No permission to any topics</span>
                                    </Empty>
                                </div>
                            </template>
                        </v-data-table>
                    </v-flex>
                    <v-flex style="padding : 30px 0 ;">
                        <v-btn class="text-none" color="primary" @click="$refs.addDb.open('add')">Add New Accessible Topic</v-btn>
                    </v-flex>
                </v-layout>
                <AddUserDatabase ref="addDb" @confirm="addUserDatabase" :dbs="items" :user="user"></AddUserDatabase>
                <Confirm ref="delDatabase" @confirm="deleteUserDatabase">
                    <span slot="title">Delete User's Topic</span>
                    <template v-slot:content="options"></template>
                </Confirm>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapState, mapGetters} from 'vuex' //mapState  mapGetters
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import AddUserDatabase from '../_source/AddUserDatabase.vue'
    import Confirm from '@/components/Confirm.vue'
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'

    export default {
        name: "DbDetail",
        components: {Confirm,Empty,Spin,AddUserDatabase},
        mixins: [loadingMixin],
        data() {
            return {
                loading: false,
                selected: '',
                user:{},
                items: [],
                headers: [
                    {text: 'Topic Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Permission', value: 'permission', sortable: false, align: 'right'},
                    {text: '', value: 'opt', sortable: false, align: 'right'},
                ],
            }
        },
        computed: {
            ...mapState('application', [ 'appInfo', 'users','databaseDetail']),
            ...mapGetters('application', ['getServiceId'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.user = {}
                    this.items = []
                } else  {
                    this.loading = true
                    this.getUserDatabasesList().then(() => {
                        this.loading = false
                    })
                }
            }
        },
        methods: {
            open(item) {
                this.dialog = true
                this.user = item
            },
            addUserDatabase(item) {
                return this.$http.put(`db-users/${this.user.uuid}/change-relation/`, {
                    relation: item.action.toUpperCase(),
                    databases: item.databases,
                    permission: item.permission
                }).then(() => {
                    this.$message.success(`The Topic was ${item.action==='update'?'updated':'added'} successfully.`)
                    this.getUserDatabasesList()
                    this.getDataBaseList(true)
                    this.getDataBaseUserList(true)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            deleteUserDatabase(item) {
                return this.$http.put(`db-users/${this.user.uuid}/change-relation/`, {
                    relation: 'DEL',
                    databases:[item.db.db_uuid]
                }).then(() => {
                    this.$message.success(`The Topic was removed successfully.`)
                    this.getUserDatabasesList()
                    this.getDataBaseList(true)
                    this.getDataBaseUserList(true)
                    return Promise.resolve()
                }).catch((e) => {
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            getUserDatabasesList() {
              return  this.$http.get(`/db-users/${this.user.uuid}`).then(res => {
                  this.items = res.db_permission
              }).catch(err => {
                  console.log(err.detail)
              })
            },
            ...mapActions('application', ['getDataBaseList', 'getDataBaseUserList','getDataBaseDetail']),
        },
    }
</script>

<style lang="scss">
    .panel-database-detail {
        .search-wrapper {
            .v-text-field {
                padding-top: 0;
            }

            .v-text-field--solo .v-input__control {
                min-height: 36px;
            }
        }
    }
</style>
