var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary", height: "48", fixed: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v("User Details(" + _vm._s(_vm.user.name) + ")")
              ])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "panel-wrapper", attrs: { "grid-list-xl": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { "mt-4": "" } },
                    [
                      _c("v-subheader", { staticClass: "panel-title" }, [
                        _vm._v("Topics")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-data-table",
                        {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.headers,
                            loading: _vm.loading,
                            items: _vm.items,
                            "hide-actions": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "items",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            disabled:
                                              item.status !== "SqlRemove"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c("span", _vm._g({}, on), [
                                                      _vm._v(_vm._s(item.name))
                                                    ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "The topic has been destroyed."
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(item.permission))
                                  ]),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    item.permission !== "Super"
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "44px"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  transition:
                                                    "slide-x-transition"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "more_vert"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  [
                                                    item.status !== "SqlRemove"
                                                      ? _c(
                                                          "v-list-tile",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$refs.addDb.open(
                                                                  "update",
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _vm._v(
                                                                  "\n                                                Update\n                                            "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-list-tile",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.delDatabase.open(
                                                              {
                                                                content:
                                                                  "Are you sure you want to remove topic " +
                                                                  item.name +
                                                                  " from the user?",
                                                                db: item
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [_vm._v("Delete")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              }
                            },
                            {
                              key: "no-data",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "min-height": "192px",
                                        position: "relative"
                                      }
                                    },
                                    [
                                      _c("Spin", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.loading,
                                            expression: "loading"
                                          }
                                        ]
                                      }),
                                      _c(
                                        "Empty",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.loading,
                                              expression: "!loading"
                                            }
                                          ]
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "No permission to any topics"
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { color: "blue", indeterminate: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "progress",
                                fn: function() {
                                  return undefined
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticStyle: { padding: "30px 0" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.addDb.open("add")
                            }
                          }
                        },
                        [_vm._v("Add New Accessible Topic")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("AddUserDatabase", {
                ref: "addDb",
                attrs: { dbs: _vm.items, user: _vm.user },
                on: { confirm: _vm.addUserDatabase }
              }),
              _c(
                "Confirm",
                {
                  ref: "delDatabase",
                  on: { confirm: _vm.deleteUserDatabase },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function(options) {
                        return undefined
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Delete User's Topic")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }